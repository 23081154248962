
    import { Vue, Component } from 'vue-property-decorator'
    import api from '@/utils/api'
    import { Rules } from '../utils/Rules';

    @Component
    export default class ForgotPassword extends Vue {
        loadingData: boolean = false;
        username = '';
        resetFailed: boolean = false;
        resetSucceeded: boolean = false;
        Rules: object = Rules;

        async submit() {
            this.loadingData = true;
            let result = await api.post<any>("login/ForgotPassword", { Username: this.username });
            if (result.ok) {
                this.resetSucceeded = true;
            }
            else {
                this.resetFailed = true;
            }
            this.loadingData = false;
        }
    }

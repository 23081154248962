export default {
    numberOfItems(ref : any) {
        let items = ref.selectedItems.length;
        while (items > 0) {
            let sum = 0;
            // Weather/Option = DisplayName, Handler = FullName, Dog = Name
            // 8.5 = width per letter
            // 24 = padding/margin per chip
            // This gives the total width of the chips in the multi-select
            ref.selectedItems.slice(0, items).forEach((item: any) => sum += ((((item.DisplayName ?? item.FullName ?? item.Name).length ?? 12) * 8.5) + 24));

            // Add on the width of the (+N others) label
            sum += 65;

            // Add on the width of the icon
            sum += 33;

            // Subtracting 52 removes the padding/margin inside the multiselect
            if (sum > (ref.$el.offsetWidth - 52)) {
                items = items - 1;
            }
            else {
                break;
            }
        }
        // Return the number of chip items that can fit in the multi-select
        return items;
    }
}

    import { Vue, Component, Prop } from 'vue-property-decorator'
    import Location from '../../models/Location'
    import api from '../../utils/api';
    import CurrentUser from '../../utils/CurrentUser';
    import { Rules } from '../../utils/Rules';

    @Component({
        name: 'ManageLocations'
    })
    export default class ManageLocations extends Vue {
        loadingData: boolean = false;
        showError: boolean = false;
        showSaveSuccess: boolean = false;
        showErrorMessage: boolean = false;
        savingData: boolean = false;
        valid: boolean = true;
        Rules: object = Rules;
        addEditLocationDialog: boolean = false;
        errorText: string = 'An error occurred';

        selectedLocation: Location = { ID: 0, Address: '', Code: '', Description: '', Postcode: '', IsDeleted: false };
        locations: Location[] = [];

        headers = [
            { text: 'Location', value: 'Code', sortable: false },
            { text: 'Description', value: 'Description', sortable: false },
            { text: 'Address', value: 'Address', sortable: false },
            { text: 'Postcode', value: 'Postcode', sortable: false },
            { text: '', value: 'IsDeleted', sortable: false, width: '15%' },
        ];

        async created() {
            this.loadingData = true;

            let locationResponse = await api.get<Location[]>('Admin/GetTrainingLocations?userToken=' + CurrentUser.currentUserToken());

            if (locationResponse.ok) {
                this.locations = locationResponse.data!;
            }
            else {
                this.showError = true;
            }

            this.loadingData = false;
        }

        addNewLocation() {
            this.selectedLocation = { ID: 0, Address: '', Code: '', Description: '', Postcode: '', IsDeleted: false };
            this.addEditLocationDialog = true;
        }

        editLocation(location: Location) {
            this.selectedLocation = Object.assign({}, location);
            this.addEditLocationDialog = true;
        }

        async addUpdateLocation() {
            (this.$refs!.form! as any).validate();

            if (this.valid) {
                this.savingData = true;
                let updateLocationResponse = await api.post<Location>('Admin/AddModifyLocation?userToken=' + CurrentUser.currentUserToken(), this.selectedLocation);

                if (updateLocationResponse.ok) {
                    let returnedLocation = updateLocationResponse.data!;
                    if (this.selectedLocation.ID == 0) {
                        this.locations.push(returnedLocation);
                    }
                    else {
                        this.locations = this.locations.map(l => l.ID !== returnedLocation.ID ? l : returnedLocation);
                    }
                    this.addEditLocationDialog = false;
                    this.showSaveSuccess = true;
                }
                else {
                    this.showErrorMessage = true;
                    this.errorText = "Error: " + (updateLocationResponse.error ?? "Could not save Location");
                }

                this.savingData = false;
            }
        }

        archiveRestoreLocation() {
            this.selectedLocation.IsDeleted = !this.selectedLocation.IsDeleted;
            this.addUpdateLocation();
        }

        getRowClass(item: Location) {
            return item.IsDeleted ? 'archived-row' : '';
        }
    }

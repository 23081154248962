
    import { Vue, Component, Prop } from 'vue-property-decorator'
    import DogVM from '../viewmodels/DogVM';
    import api from '../utils/api'
    import CurrentUser from '../utils/CurrentUser';

    @Component({
        name: 'DogProfiles'
    })
    export default class DogProfiles extends Vue {
        search: string = '';
        selectedBreed: string = '';
        select: string = '';
        loadingData: boolean = false;
        showError: boolean = false;
        showArchived: boolean = false;

        headers = [
            { text: 'Name', value: 'Name', width:'28%' },
            { text: 'Breed', value: 'Breed', width: '30%' },
            { text: 'Colour', value: 'Colour', filterable: false, width: '22%' },
            { text: 'Status', value: 'IsDeleted', filterable: false, width: '20%' },
        ];

        dogProfiles: DogVM[] = [];
        dogProfilesFiltered: DogVM[] = [];
        breeds: string[] = [];

        async created() {
            this.loadingData = true;
            let dogProfileResponse = await api.get<DogVM[]>('Dog/AllDogs?userToken=' + CurrentUser.currentUserToken());
            
            if (dogProfileResponse.ok) {
                this.dogProfiles = dogProfileResponse.data!;
                this.dogProfilesFiltered = dogProfileResponse.data!.filter(d => !d.IsDeleted);
                
            }
            else {
                this.showError = true;
            }

            this.loadingData = false;
        }

        filterDogProfiles() {
            if (this.showArchived) {
                this.dogProfilesFiltered = this.dogProfiles;
            }
            else {
                this.dogProfilesFiltered = this.dogProfiles.filter(d => !d.IsDeleted)
            }
        }

        viewEditDogProfile(item: DogVM) {
            this.$router.push('/DogProfileDetails/' + item.ID);
        }

        createNewDogProfile() {
            this.$router.push('/DogProfileDetails/0');
        }

        checkPermission(permission: string) : boolean {
            return CurrentUser.hasPermission(permission);
        }

        getRowClass(item: DogVM) {
            return item.IsDeleted ? 'archived-row' : '';
        }
    }

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import vueSignature from 'vue-signature-pad'

Vue.config.productionTip = false
Vue.use(vueSignature)
Vue.component('custom-checkbox', () => import('@/components/CustomCheckbox.vue'))

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')

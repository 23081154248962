import Vue from 'vue';
import CurrentUser from '../utils/CurrentUser';
import router from '../router';
import LoginResponse from '../models/LoginResponse';

export interface ApiResponse<T> {
    ok: boolean;
    error: string | undefined;
    status: number;
    statusText: string;
    data: T | null;
}

export default {
    // Must have trailing '/'
    baseUrl: process.env.VUE_APP_API_BASE_URL,
    // Must match equivalent error message on server side.
    INVALID_USER_TOKEN : "Invalid user token.",
    vm: new Vue(),

    /**
     * Pass in the main Vue instance to use the router if api calls return 401
     * @param {Vue} vm - Vue instance
     */
    init(vm: Vue) {
        this.vm = vm;
    },

    /**
     * 
     * @param {string} path
     * @param {any} options
     */
    async fetchWithRedirect(path: string, options?: RequestInit, retry: boolean = false) {
        const accessToken = localStorage.getItem('AccessToken');

        const reqHeaders = new Headers(options!.headers);
        reqHeaders.set('Authorization', `Bearer ${accessToken}`);
        options!.headers = reqHeaders;

        const result = await fetch(path, options);

        if (result.status === 401) {
            if (router.currentRoute.path != '/login') {
                if (!retry) {
                    // Try to get new access token using the userToken and try the request again
                    const user = localStorage.getItem('CurrentUser');
                    const userToken = user ? (JSON.parse(user) as LoginResponse).UserToken : '';
                    if (userToken) {
                        const refreshResponse = await fetch(this.baseUrl + 'login/refreshaccesstoken', {
                            method: 'POST',
                            body: JSON.stringify(userToken),
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        });
                        if (refreshResponse.ok) {
                            var newAccessToken = await refreshResponse.text();
                            localStorage.setItem('AccessToken', newAccessToken);

                            // Retry = true so refresh is only attempted once, with the new access token in the headers
                            let retriedResult: Response = await this.fetchWithRedirect(path, options, true);
                            return retriedResult;
                        }
                        else {
                            // Redirect to login page
                            localStorage.removeItem('CurrentUser');
                            router.replace({
                                path: '/login',
                                query: {
                                    redirect: router.currentRoute.fullPath,
                                }
                            });
                        }
                    }
                    else {
                        // Redirect to login page
                        localStorage.removeItem('CurrentUser');
                        router.replace({
                            path: '/login',
                            query: {
                                redirect: router.currentRoute.fullPath,
                            }
                        });
                    }
                }
                else {
                    // Redirect to login page
                    localStorage.removeItem('CurrentUser');
                    router.replace({
                        path: '/login',
                        query: {
                            redirect: router.currentRoute.fullPath,
                        }
                    });
                }
            }
            return new Response('null');
        }
        return result;
    },

    async getFile<T = any>(path: string): Promise<ApiResponse<Blob|null>> {
        try {
            const result = await this.fetchWithRedirect(this.baseUrl + path, { });
            var file;

            if (result.ok) {
                let parsedData = await result.arrayBuffer();
                file = new Blob([parsedData], { type: 'application/png' });
            }

            const r: ApiResponse<Blob> = {
                ok: result.ok,
                error: result.ok ? "" : result.statusText,
                status: result.status,
                statusText: result.statusText,
                data: (result.ok && file) ? file : null
            };

            return r;
        }
        catch (err) {
            const r: ApiResponse<null> = {
                ok: false,
                error: err.message,
                status: 999,
                statusText: err.message,
                data: null
            };

            return r;
        }
    },

    async downloadPdf<T = any>(path: string, data: any): Promise<ApiResponse<Blob | null>> {
        try {
            const result = await this.fetchWithRedirect(this.baseUrl + path, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            var parsedData;
            var error;
            var file;

            if (result.ok) {
                try {
                    parsedData = await result.arrayBuffer();
                    file = new Blob([parsedData], { type: 'application/pdf' });
                }
                catch {
                    parsedData = null;
                }
            }
            else {
                try {
                    error = await result.text();
                }
                catch {
                    error = "";
                }
            }

            const r: ApiResponse<Blob> = {
                ok: result.ok,
                error: result.ok ? "" : result.statusText,
                status: result.status,
                statusText: result.statusText,
                data: (result.ok && file) ? file : null
            };

            return r;
        }
        catch (err) {
            const r: ApiResponse<null> = {
                ok: false,
                error: err.message,
                status: 999,
                statusText: err.message,
                data: null
            };

            return r;
        }
    },

    async get<T = any>(path: string): Promise<ApiResponse<T>> {
        try {
            const result = await this.fetchWithRedirect(this.baseUrl + path, { });
            var parsedData;
            var error;

            if (result.ok) {
                parsedData = await result.json();
            }
            else {
                error = await result.text();
            }

            const r: ApiResponse<T> = {
                ok: result.ok && !parsedData.Error,
                error: result.ok ? parsedData.Error : error,
                status: result.status,
                statusText: result.statusText,
                data: (result.ok && !parsedData.Error) ? parsedData : null
            };

            return r;
        }
        catch (err) {
            const r: ApiResponse<T> = {
                ok: false,
                error: err.message,
                status: 999,
                statusText: err.message,
                data: null
            };

            return r;
        }
    },

    async post<T = any>(path: string, data: any): Promise<ApiResponse<T>> {
        try {
            const result = await this.fetchWithRedirect(this.baseUrl + path, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            var parsedData;
            var error;

            if (result.ok) {
                try {
                    parsedData = await result.json();
                }
                catch {
                    parsedData = null;
                }
            }
            else {
                try {
                    error = await result.text();
                }
                catch {
                    error = "";
                }
            }
            const r: ApiResponse<T> = {
                ok: result.ok && (!parsedData || !parsedData.Error),
                error: result.ok ? (parsedData ? parsedData.Error : "") : error,
                status: result.status,
                statusText: result.statusText,
                data: (result.ok && (!parsedData || !parsedData.Error)) ? parsedData : (parsedData ? parsedData.Error : null)
            };

            return r;
        }
        catch (err) {
            const r: ApiResponse<T> = {
                ok: false,
                error: err.message,
                status: 999,
                statusText: err.message,
                data: null
            };

            return r;
        }
    },

    async put<T = any>(path: string, data: any) {
        const result = await this.fetchWithRedirect(this.baseUrl + path, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return result.json() as Promise<T>;
    },

    async delete(path: string) {
        await this.fetchWithRedirect(this.baseUrl + path, {
            method: 'DELETE'
        });
    },

    async postWithFiles<T = any>(path: string, data: any) {
        const formData = new FormData();
        formData.append(
            "file",
            data,
            data.name
        );
        const result = await this.fetchWithRedirect(this.baseUrl + path, {
            method: 'POST',
            body: formData
        });

        var parsedData;

        try {
            parsedData = await result.json();
        }
        catch {
            parsedData = null;
        }

        const r: ApiResponse<T> = {
            ok: result.ok,
            error: result.ok ? "" : result.statusText,
            status: result.status,
            statusText: result.statusText,
            data: result.ok ? parsedData : null
        };

        return r;
    },

    async DownloadFile(fileID: number) : Promise<string | null> {
        try {
            let downloadFileResponse = await this.getFile<any>('File/DownloadFile?userToken=' + CurrentUser.currentUserToken() + '&ID=' + fileID);
            if (downloadFileResponse.ok) {
                return URL.createObjectURL(downloadFileResponse.data!);
            }
            else {
                return null;
            }
        }
        catch {
            return null;
        }
    }
}
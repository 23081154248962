
    import { Vue, Component, Prop } from 'vue-property-decorator'
    import TAPRecordDetailDTO from '../DTOs/TAPRecordDetailDTO';
    import TAPRecord from '../models/TAPRecord'
    import api from '../utils/api'
    import Option from '../models/Option'
    import DateTimeUtil from '../utils/DateTimeUtil'
    import router from '../router';
    import DogHandlerTeam from '../models/DogHandlerTeam';
    import CurrentUser from '../utils/CurrentUser';
    import Location from '../models/Location';
    import { Rules } from '../utils/Rules';
    import $ from "jquery";

    @Component({
        name: 'TAPRecord'
    })
    export default class TAPRecordDetails extends Vue {
        @Prop(Number) id!: number;

        editMode: boolean = false;
        date: Date | null | string = null;
        dateModal: boolean = false;
        timeModal: boolean = false;
        time: string | null = null;

        durationError: boolean = false;
        duration = { Hours: 0, Minutes: 0 };
        hours = [0];
        minutes = [0];

        loadingData: boolean = false;
        savingData: boolean = false;
        showError: boolean = false;
        showErrorMessage: boolean = false;
        errorText: string = "An error occurred";
        showSaveSuccess: boolean = false;
        confirmArchiveRestoreModal: boolean = false;
        valid: boolean = true;
        Rules: object = Rules;

        TAPRecord: TAPRecord = {
            ID: 0, Completed: undefined, Location: undefined, Task: undefined, DogHandlerTeam: undefined, Comments: '', IsDeleted: false, DurationMinutes: 0, CompetentPerson:''
        };

        TrainingLocations: Location[] = [];
        TrainingTasks: Option[] = [];
        Substances: Option[] = [];

        DogHandlerTeams: DogHandlerTeam[] = [];

        async created() {
            this.loadingData = true;
            for (let i = 1; i <= 8; i++) { this.hours.push(i) };
            for (let i = 5; i < 60; i = i + 5) { this.minutes.push(i) };

            let TAPRecordDetailResponse = await api.get<TAPRecordDetailDTO>('Training/TAPRecordDetail?id=' + this.id + '&userToken=' + CurrentUser.currentUserToken());

            if (TAPRecordDetailResponse.ok) {

                let responseData = TAPRecordDetailResponse.data;
                this.TrainingLocations = responseData!.LocationOptions;
                this.TrainingTasks = responseData!.TrainingTaskOptions;
                this.DogHandlerTeams = responseData!.DogHandlerTeams;
                this.Substances = responseData!.SubstanceOptions;

                if (this.id != 0) {
                    this.TAPRecord = responseData!.TAPRecord;
                    //DateTime fields1
                    [this.date, this.time] = DateTimeUtil.splitDate(new Date(this.TAPRecord.Completed!));

                    if (this.TAPRecord.DurationMinutes) {
                        //Duration fields
                        [this.duration.Hours, this.duration.Minutes] = DateTimeUtil.splitTimeMinutes(this.TAPRecord.DurationMinutes!);
                    }
                }

                // Handlers can only view their own training records
                if (CurrentUser.currentUserRole() == "Handler" && this.TAPRecord.DogHandlerTeam?.Handler.ID != CurrentUser.currentUserID() && this.TAPRecord.ID != 0) {
                    this.$router.back();
                }

                this.editMode = CurrentUser.hasPermission('EditTAPRecords') && this.TAPRecord.ID == 0;
            }
            else {
                this.showError = true;
            }
            this.loadingData = false;
        }

        async saveTAPRecord() {
            (this.$refs!.form! as any).validate();
            this.durationValidation();

            if (this.valid && !this.durationError) {
                this.savingData = true;
                this.TAPRecord.Completed = DateTimeUtil.createDateTime(this.date! as string, this.time!);
                this.TAPRecord.DurationMinutes = DateTimeUtil.convertToMinutes(this.duration.Hours, this.duration.Minutes);

                let TAPRecordUploadResponse = await api.post<null>('Training/SaveTAPRecord?userToken=' + CurrentUser.currentUserToken(), this.TAPRecord);

                if (TAPRecordUploadResponse.ok) {
                    this.showSaveSuccess = true;
                    this.$router.push('/TAPRecords');
                }
                else {
                    this.showErrorMessage = true;
                    this.errorText = "Error: " + (TAPRecordUploadResponse.error ?? "Could not save Training Record");
                }
                this.savingData = false;
            }
            else
            {
                this.$nextTick(() => {
                    const el = this.$el.querySelector(".v-messages.error--text:first-of-type");

                    const yOffset = -200;
                    const y = el!.getBoundingClientRect().top + window.pageYOffset + yOffset;

                    window.scrollTo({ top: y, behavior: 'smooth' });

                    return;
                });
                return;
            }
        }

        async archiveRestoreRecord() {
            this.savingData = true;
            this.TAPRecord.IsDeleted = !this.TAPRecord.IsDeleted;
            let TAPRecordUploadResponse = await api.post<null>('Training/SaveTAPRecord?userToken=' + CurrentUser.currentUserToken(), this.TAPRecord);

            if (TAPRecordUploadResponse.ok) {
                this.showSaveSuccess = true;
                this.$router.push('/TAPRecords');
            }
            else {
                this.showErrorMessage = true;
                this.errorText = "Error: " + (TAPRecordUploadResponse.error ?? "Could not archive Training Record");
            }

            this.savingData = false;
            this.confirmArchiveRestoreModal = false;
        }

        durationValidation() {
            if ((this.duration.Hours != 0 || this.duration.Minutes != 0) && !(this.duration.Hours == 8 && this.duration.Minutes > 0)) {
                this.durationError = false;
            }
            else {
                this.durationError = true;
            }
        }

        setActiveClass(e: any) {
            $("#durationInput").toggleClass('v-input--is-focused');
            $("#durationInput").toggleClass('primary--text');
        }

        durationInputOnFocus() {
            $('.duration-input').addClass('v-input--is-focused');
            $('.duration-input').addClass('primary--text');
            $('.duration-input .v-icon').addClass('primary--text');
        }

        durationInputOnUnfocus() {
            $('.duration-input').removeClass('v-input--is-focused');
            $('.duration-input').removeClass('primary--text');
            $('.duration-input .v-icon').removeClass('primary--text');
        }

        goBack() {
            router.back();
        }
    }

import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);


export default new Vuetify({
    defaults: {
        VBtn: {
            color: 'primary',
            rounded: 'lg',
            size: 'x-large',
        },
    },
    theme: {
        themes: {
            light: {
                primary: '#F0AD4E',
                primarytext: '#ffffff',
                headertext: '#201f1f',
                hover: '#f3f3f3',
                secondary: '#F0AD4E',
                header: '#4e4e4e',
                bground: '#ededed',
                footer: '#9c9c9c',
                submitted: '#fff87e',
                restore: '#75bdf7'
            }
        },
    },
});

import User from "./User";

export default interface Dog {
    ID: number;
    Name: string;
    Breed: string;
    Colour: string;
    MicrochipNumber: string;
    Sex: Sex;
    Neutered: boolean;
    Notes: string;
    IsDeleted: boolean;
}

export const enum Sex {
    Male = 'Male', 
    Female = 'Female'
}
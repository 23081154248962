
    import { Vue, Component, Prop } from 'vue-property-decorator'
    import router from '../router';
    import Dog from '../models/Dog';
    import { Sex } from '../models/Dog';
    import api from '../utils/api';
    import DogDetailDTO, { DogUploadDTO } from '../DTOs/DogDetailDTO'
    import User from '../models/User'
    import CurrentUser from '../utils/CurrentUser';
    import FileVM from '../viewmodels/FileVM';
    import FileHelper from '../utils/FileHelper';
    import MultiSelectUtil from '../utils/MultiSelectUtil';
    import { Rules } from '../utils/Rules';

    @Component({
        name: 'DogProfiles'
    })
    export default class DogProfileDetails extends Vue {
        @Prop(Number) id!: number;
        loadingData: boolean = false;
        savingData: boolean = false;
        uploadingFiles: boolean = false;
        showError: boolean = false;
        showErrorMessage: boolean = false;
        errorText: string = 'An error occurred';
        showSaveSuccess: boolean = false;
        confirmArchiveRestoreModal: boolean = false;
        confirmDeleteFileModal: boolean = false;
        valid: boolean = true;
        Rules: object = Rules;
        newDogProfile: boolean = this.id === 0;
        fileForDeletion: FileVM | null = null;

        editMode: boolean = CurrentUser.hasPermission('EditAllDogs');
        
        DogProfile: Dog = {ID: 0, Name: '', Breed: '', Sex:Sex.Male, Colour: '', MicrochipNumber: '', Neutered: false, Notes: '', IsDeleted: false};
        DogHandlers: User[] = [];
        handlerItems: number = 0;

        Sexes = [Sex.Male, Sex.Female];

        headers = [
            { text: 'Name', value: 'Name', sortable: false },
            { text: 'File Type', value: 'FileType', sortable: false },
            { text: 'Uploaded', value: 'Uploaded', sortable: false },
            { text: '', value: 'Download', sortable: false, width: '6%' },
            { text: '', value: 'Delete', sortable: false, width: '6%' },
        ];

        // file-input only stores last added files
        lastAddedFiles: File[] = [];
        // filesForUpload includes all added files that need uploading
        fileVMs: FileVM[] = [];

        async created() {
            this.loadingData = true;
            if (this.id != 0) {
                let dogDetailResponse = await api.get<DogDetailDTO>('Dog/DogDetail?id=' + this.id + '&userToken=' + CurrentUser.currentUserToken());
                if (dogDetailResponse.ok) {
                    if (dogDetailResponse.data!.Dog) {
                        this.DogProfile = dogDetailResponse.data!.Dog;
                    }
                    if (dogDetailResponse.data!.Handlers) {
                        this.DogHandlers = dogDetailResponse.data!.Handlers;
                        this.editMode = this.editMode || this.DogHandlers.map(h => h.Email).includes(CurrentUser.currentUser().UserName);
                    }
                    if (dogDetailResponse.data!.Documents) {
                        this.fileVMs = dogDetailResponse.data!.Documents;
                    }
                    this.loadingData = false;
                }
                else {
                    this.showError = true;
                    this.loadingData = false;
                }
            }
            else {
                this.loadingData = false;
            }
        }

        async saveDogProfile() {
            (this.$refs!.form! as any).validate();

            if (this.valid) {
                this.savingData = true;

                var dogUploadDTO = new DogUploadDTO(this.DogProfile);

                let dogDetailUploadResponse = await api.post<number>('Dog/SaveDogDetails?userToken=' + CurrentUser.currentUserToken(), dogUploadDTO);
                
                if (dogDetailUploadResponse.ok) {
                    let dogProfileID = dogDetailUploadResponse.data!;

                    // Upload files
                    this.uploadingFiles = true;
                    let addDeleteFilesResponse = await FileHelper.addDeleteFiles(this.fileVMs, "TBL_Dog", dogProfileID);
                    this.fileVMs = addDeleteFilesResponse.returnArray;
                    this.uploadingFiles = false;
                    this.showSaveSuccess = addDeleteFilesResponse.success;

                    this.$router.push('/DogProfiles');
                }
                else {
                    this.showErrorMessage = true;
                    this.errorText = "Error: " + (dogDetailUploadResponse.error ?? "Could not save Dog Profile");
                }

                this.savingData = false;
            }
            else {
                this.$nextTick(() => {
                    const el = this.$el.querySelector(".v-messages.error--text:first-of-type");

                    const yOffset = -200;
                    const y = el!.getBoundingClientRect().top + window.pageYOffset + yOffset;

                    window.scrollTo({ top: y, behavior: 'smooth' });

                    return;
                });
                return;
            }
        }

        async archiveRestoreProfile() {
            this.savingData = true;
            var dogUploadDTO = new DogUploadDTO(this.DogProfile);
            dogUploadDTO.IsDeleted = !dogUploadDTO.IsDeleted;
            let handlerDetailUploadResponse = await api.post<null>('Dog/SaveDogDetails?userToken=' + CurrentUser.currentUserToken(), dogUploadDTO);
            if (handlerDetailUploadResponse.ok) {
                this.showSaveSuccess = true;

                this.$router.push('/DogProfiles');
            }
            else {
                this.showErrorMessage = true;
                this.errorText = "Error: " + (handlerDetailUploadResponse.error ?? "Could not archive Dog profile");
            }
            this.confirmArchiveRestoreModal = false;
            this.savingData = false;
        }

        goBack() {
            router.back(); 
        }

        addFile() {
            for (let i = 0; i < this.lastAddedFiles.length; i++) {
                let file = (this.lastAddedFiles[i] as File);
                this.fileVMs.push({ ID: 0, Name: file.name, FileType: file.type.split('/').pop() || '', Uploaded: new Date, ExternalFileName: '', InternalFileName: '', IsDeleted: false, File: file });
            }
        }

        confirmDeleteFile(file: FileVM) {
            this.fileForDeletion = file;
            this.confirmDeleteFileModal = true;
        }

        async downloadFile(fileInfo: FileVM) {
            let file = await api.DownloadFile(fileInfo.ID);
            if (file) {
                const link = document.createElement('a');
                link.href = file;
                link.download = fileInfo.ExternalFileName;
                link.click();
            }
            else {
                this.showErrorMessage = true;
                this.errorText = "Error: Could not download file";
            }
        }

        async deleteFile() {
            let fileInfo = this.fileForDeletion;
            if (fileInfo) {
                if (fileInfo.ID == 0) {
                    const index = this.fileVMs.indexOf(fileInfo);
                    if (index > -1) this.fileVMs.splice(index, 1);
                }
                else {
                    fileInfo.IsDeleted = true;
                }
            }
            this.confirmDeleteFileModal = false;
        }

        mounted() {
            this.$nextTick(() => {
                window.addEventListener('resize', this.detectResizeForHandlerSelect);
                window.addEventListener('load', this.detectResizeForHandlerSelect);
            });
        }

        updated() {
            this.detectResizeForHandlerSelect();
        }

        detectResizeForHandlerSelect() {
            if (this.$refs.handlerMultiSelect) {
                this.handlerItems = MultiSelectUtil.numberOfItems(this.$refs.handlerMultiSelect);
            }
        }
    }

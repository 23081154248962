
    import { Vue, Component, Prop } from 'vue-property-decorator'
    import User from '../models/User';
    import api from '../utils/api'
    import CurrentUser from '../utils/CurrentUser';

    @Component({
        name: 'HandlerProfiles'
    })
    export default class HandlerProfiles extends Vue {
        search: string = '';
        select: string = '';
        loadingData: boolean = false;
        showError: boolean = false;

        headers = [
            { text: 'First Name', value: 'FirstName', width:'23%' },
            { text: 'Surname', value: 'Surname', width: '20%' },
            { text: 'Email', value: 'Email', filterable: false, width: '31%' },
            { text: 'SIA Number', value: 'SIANumber', filterable: false, width: '26%' },
        ];

        handlerProfiles: User[] = [];

        async created() {
            this.loadingData = true;
            let handlerProfileResponse = await api.get<User[]>('User/AllHandlers?userToken=' + CurrentUser.currentUserToken());

            if (handlerProfileResponse.ok) {
                this.handlerProfiles = handlerProfileResponse.data!;
            }
            else {
                this.showError = true;
            }

            this.loadingData = false;
        }

        viewEditHandlerProfile(item: User) {
            this.$router.push('/HandlerProfileDetails/' + item.ID);
        }
    }


    import { Vue, Component, Prop } from 'vue-property-decorator'
    import DogHandlerTeamDetailDTO from '../../DTOs/DogHandlerTeamDetailDTO';
    import DogHandlerTeam from '../../models/DogHandlerTeam';
    import api from '../../utils/api'
    import CurrentUser from '../../utils/CurrentUser';
    import { Rules } from '../../utils/Rules';

    interface Filters {
        DateTo: Date | null | string;
        DateFrom: Date | null | string;
        Team: DogHandlerTeam | null;
        Location: Location | null;
    }

    @Component({
        name: 'Reports'
    })
    export default class ManageDropdowns extends Vue {
        loadingData: boolean = false;
        runningReport: boolean = false;
        showError: boolean = false;
        showErrorMessage: boolean = false;
        errorText: string = 'An error occurred';
        showSaveSuccess: boolean = false;
        valid: boolean = true;
        Rules: object = Rules;
        fromDate: Date | null | string = null;
        fromDateModal: boolean = false;
        toDate: Date | null | string = null;
        toDateModal: boolean = false;
        DogHandlerTeams: DogHandlerTeam[] = [];
        DogHandlerTeam: DogHandlerTeam | null = null;
        Locations: Location[] = [];
        Location: Location | null = null;
        reportType: string = 'Team';

        Reports: any[] = [{ ReportType: 'Team' },
            { ReportType: 'Location' }];

        async created() {
            await this.loadOptions();
        }

        async loadOptions() {
            this.loadingData = true;
            let dogHandlerTeamsResponse = await api.get<DogHandlerTeamDetailDTO>('Admin/GetTeams?userToken=' + CurrentUser.currentUserToken());

            if (dogHandlerTeamsResponse.ok) {
                this.DogHandlerTeams = dogHandlerTeamsResponse.data!.DogHandlerTeams;
                this.Locations = dogHandlerTeamsResponse.data!.Locations;
            }
            else {
                this.showError = true;
            }

            this.loadingData = false;
        }

        async generateReport() {
            
            ((this.$refs!.form! as any)).validate();
            if (this.valid)
            { 
                this.runningReport = true;
                let filters: Filters = { DateFrom: this.fromDate, DateTo: this.toDate, Team: null, Location: null };
                if (this.reportType == 'Team') {
                    filters.Team = this.DogHandlerTeam;
                }
                else if (this.reportType == 'Location') {
                    filters.Location = this.Location;
                }

                let generateReportResponse = await api.downloadPdf<any>('Reports/RunReport?userToken=' + CurrentUser.currentUserToken(), filters);

                if (generateReportResponse.ok) {
                    let file = URL.createObjectURL(generateReportResponse.data!);
                    if (file) {
                        const link = document.createElement('a');
                        link.href = file;
                        link.download = "SummaryReport_" + Date.now() + ".pdf";
                        link.click();
                    }
                    else {
                        this.showErrorMessage = true;
                    }
                }
                else {
                    this.showError = true;
                }
            }
            this.runningReport = false;
        }
    }


    import { Vue, Component, Prop } from 'vue-property-decorator'
    import DogHandlerTeam from '../../models/DogHandlerTeam';
    import api from '../../utils/api'
    import Dog, { Sex } from '../../models/Dog'
    import User from '../../models/User';
    import DogHandlerTeamDetailDTO from '../../DTOs/DogHandlerTeamDetailDTO';
    import CurrentUser from '../../utils/CurrentUser';
    import { Rules } from '../../utils/Rules';
import DateTimeUtil from '../../utils/DateTimeUtil';

    @Component({
        name: 'ManageTeams'
    })
    export default class ManageTeams extends Vue {
        loadingData: boolean = false;
        showError: boolean = false;
        showSaveSuccess: boolean = false;
        showErrorMessage: boolean = false;
        savingData: boolean = false;
        valid: boolean = true;
        Rules: object = Rules;
        addTeamDialog: boolean = false;
        errorText: string = 'An error occurred';
        confirmArchiveTeamModal: boolean = false;
        accreditationDateModal: boolean = false;

        selectedTeam: DogHandlerTeam = {
            ID: 0,
            Dog: { ID: 0, Breed: '', Colour: '', MicrochipNumber: '', Name: '', Neutered: false, Notes: '', Sex: Sex.Male, IsDeleted: false },
            Handler: { ID: 0, Email: '', FirstName: '', Surname: '', SIANumber: '', FullName: '', IsDeleted: false, UserRole: undefined },
            AccreditationDate: undefined,
            IsDeleted: false
        };

        DogHandlerTeams: DogHandlerTeam[] = [];
        FilteredDogHandlerTeams: DogHandlerTeam[] = [];
        Handlers: User[] = [];
        Dogs: Dog[] = [];

        FilteredDog: Dog | null = null;
        FilteredHandler: User | null = null;

        headers = [
            { text: 'Dog', value: 'Dog.Name', sortable: false },
            { text: 'Handler', value: 'Handler.FullName', sortable: false },
            { text: 'Accreditation Date', value: 'AccreditationDate', sortable: true },
            { text: '', value: 'Add', sortable: false, width:'10%' },
        ];

        async created() {
            this.loadingData = true;
            let dogHandlerTeamsResponse = await api.get<DogHandlerTeamDetailDTO>('Admin/GetTeams?userToken=' + CurrentUser.currentUserToken());

            if (dogHandlerTeamsResponse.ok) {
                this.DogHandlerTeams = dogHandlerTeamsResponse.data!.DogHandlerTeams;
                this.FilteredDogHandlerTeams = dogHandlerTeamsResponse.data!.DogHandlerTeams;
                this.Dogs = dogHandlerTeamsResponse.data!.Dogs;
                this.Handlers = dogHandlerTeamsResponse.data!.Handlers;
            }
            else {
                this.showError = true;
            }
            this.loadingData = false;
        }

        filterTeams() {
            this.FilteredDogHandlerTeams = this.DogHandlerTeams.filter(dht => (!this.FilteredDog || dht.Dog.ID === this.FilteredDog!.ID) && (!this.FilteredHandler || dht.Handler.ID === this.FilteredHandler!.ID));
        }

        addNewTeam() {
            if (this.FilteredDog) {
                this.selectedTeam.Dog = this.FilteredDog;
            }
            if (this.FilteredHandler) {
                this.selectedTeam.Handler = this.FilteredHandler;
            }
            
            this.addTeamDialog = true;
        }

        viewEditTeam(item: DogHandlerTeam, index: any, event: any) {
            if (event.target.cellIndex < (this.headers.length - 1) && !item.IsDeleted) {
                this.selectedTeam = Object.assign({}, item);
                this.selectedTeam.AccreditationDate = this.selectedTeam.AccreditationDate ? DateTimeUtil.splitDate(new Date(this.selectedTeam.AccreditationDate))[0] : undefined;
                this.addTeamDialog = true;
            }
        }

        async addTeam() {
            (this.$refs!.form! as any).validate();

            if (this.valid) {
                this.savingData = true;
                let addTeamResponse = await api.post<DogHandlerTeam>('Admin/AddModifyTeam?userToken=' + CurrentUser.currentUserToken(), this.selectedTeam);

                if (addTeamResponse.ok) {
                    this.addTeamDialog = false;
                    this.showSaveSuccess = true;

                    let returnedTeam = addTeamResponse.data!;

                    if (this.selectedTeam.ID == 0) {
                        this.DogHandlerTeams.push(returnedTeam as DogHandlerTeam);
                    }
                    else {
                        this.DogHandlerTeams = this.DogHandlerTeams.map(u => u.ID !== returnedTeam.ID ? u : returnedTeam);
                    }
                    
                    this.filterTeams();
                }
                else {
                    this.errorText = "Error: " + (addTeamResponse.error ?? "Could not add team");
                    this.showErrorMessage = true;
                }

                this.savingData = false;
            }
        }

        confirmArchiveTeam(team: DogHandlerTeam) {
            this.selectedTeam = team;
            this.confirmArchiveTeamModal = true;
        }

        async archiveRestoreTeam() {
            this.savingData = true;
            this.selectedTeam.IsDeleted = !this.selectedTeam.IsDeleted;
            let archiveTeamResponse = await api.post<null>('Admin/ArchiveTeam?userToken=' + CurrentUser.currentUserToken(), this.selectedTeam);

            if (archiveTeamResponse.ok) {
                this.showSaveSuccess = true;
            }
            else {
                this.errorText = "Error: " + (archiveTeamResponse.error ?? "Could not archive team");
                this.showErrorMessage = true;
            }

            this.confirmArchiveTeamModal = false
            this.savingData = false;
        }

        cancel() {
            this.selectedTeam = {
                ID: 0,
                Dog: { ID: 0, Breed: '', Colour: '', MicrochipNumber: '', Name: '', Neutered: false, Notes: '', Sex: Sex.Male, IsDeleted: false },
                Handler: { ID: 0, Email: '', FirstName: '', Surname: '', SIANumber: '', FullName: '', IsDeleted: false, UserRole: undefined },
                IsDeleted: false
            };
            (this.$refs.form as any).resetValidation();
            this.addTeamDialog = false;
        }

        getRowClass(item: DogHandlerTeam) {
            return item.IsDeleted ? 'archived-row' : '';
        }
    }

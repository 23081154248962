
    import { Vue, Component, Prop } from 'vue-property-decorator'
    import router from '../router';
    import User from '../models/User';
    import { Sex } from '../models/Dog'
    import api from '../utils/api';
    import UserRole from '../models/UserRole';
    import HandlerProfileDetailDTO from '../DTOs/HandlerProfileDetailDTO';
    import Dog from '../models/Dog'
    import CurrentUser from '../utils/CurrentUser';
    import MultiSelectUtil from '../utils/MultiSelectUtil';
    import { Rules } from '../utils/Rules';

    @Component({
        name: 'DogProfiles'
    })
    export default class HandlerProfileDetails extends Vue {
        @Prop(Number) id!: number;
        loadingData: boolean = false;
        savingData: boolean = false;
        showError: boolean = false;
        showSaveSuccess: boolean = false;
        confirmArchiveRestoreModal: boolean = false;
        valid: boolean = true;
        Rules: object = Rules;
        newHandlerProfile: boolean = this.id === 0;

        dogItems: number = 0;

        HandlerProfile: User = { ID: 0, FirstName: '', Surname: '', Email: '', UserRole: undefined, SIANumber: '', FullName: '', IsDeleted: false };
        UserRoles: UserRole[] = [];
        HandlerDogs: Dog[] = [];
        Sexes = [Sex.Male, Sex.Female];

        headers = [
            { text: 'Name', value: 'FileName', sortable: false },
            { text: 'File Type', value: 'FileType', sortable: false },
            { text: 'Uploaded', value: 'Uploaded', sortable: false },
            { text: '', value: 'Download', sortable: false, width:'15%' },
        ];

        async created() {
            this.loadingData = true;
            let handlerDetailResponse = await api.get<HandlerProfileDetailDTO>('User/HandlerDetail?id=' + this.id + '&userToken=' + CurrentUser.currentUserToken());

            if (handlerDetailResponse.ok) {
                if (handlerDetailResponse.data!.UserDTO) {
                    this.HandlerProfile = handlerDetailResponse.data!.UserDTO;
                }
                if (handlerDetailResponse.data!.UserRoles) {
                    this.UserRoles = handlerDetailResponse.data!.UserRoles;
                    this.HandlerProfile.UserRole = this.UserRoles.filter(ur => ur.Symbol === "Handler")[0];
                }
                if (handlerDetailResponse.data!.HandlerDogs) {
                    this.HandlerDogs = handlerDetailResponse.data!.HandlerDogs;
                }
                this.loadingData = false;
            }
            else {
                this.showError = true;
                this.loadingData = false;
            }
        }

        async saveHandlerProfile() {
            (this.$refs!.form! as any).validate();

            if (this.valid) {

                this.savingData = true;

                let handlerDetailUploadResponse = await api.post<null>('User/SaveUserDetails?userToken=' + CurrentUser.currentUserToken(), this.HandlerProfile);

                if (handlerDetailUploadResponse.ok) {
                    this.showSaveSuccess = true;
                    this.savingData = false;
                    this.$router.push('/HandlerProfiles');
                }
                else {
                    // TODO error
                }
            }
            else {
                this.$nextTick(() => {
                    const el = this.$el.querySelector(".v-messages.error--text:first-of-type");
                    /*el!.scrollIntoView();*/

                    const yOffset = -200;
                    const y = el!.getBoundingClientRect().top + window.pageYOffset + yOffset;

                    window.scrollTo({ top: y, behavior: 'smooth' });

                    return;
                });
                return;
            }
        }

        async archiveRestoreProfile() {
            this.savingData = true;
            this.HandlerProfile.IsDeleted = !this.HandlerProfile.IsDeleted;
            let handlerDetailUploadResponse = await api.post<null>('User/SaveUserDetails?userToken=' + CurrentUser.currentUserToken(), this.HandlerProfile);
            if (handlerDetailUploadResponse.ok) {
                this.showSaveSuccess = true;
                this.savingData = false;

                this.$router.push('/HandlerProfiles');
            }
            else {
                // TODO error
            }
        }

        goBack() {
            router.back();
        }

        mounted() {
            this.$nextTick(() => {
                window.addEventListener('resize', this.detectResizeForDogSelect);
                window.addEventListener('load', this.detectResizeForDogSelect);
            });
        }

        updated() {
            this.detectResizeForDogSelect();
        }

        detectResizeForDogSelect() {
            if (this.$refs.dogMultiSelect) {
                this.dogItems = MultiSelectUtil.numberOfItems(this.$refs.dogMultiSelect);
            }
        }
    }

import Dog from '../models/Dog'
import User from '../models/User';
import FileVM from '../viewmodels/FileVM';
export default interface DogDetailDTO {
    Dog: Dog;
    Handlers: User[];
    Documents: FileVM[];
}

export class DogUploadDTO {
    ID?: number;
    Name?: string;
    Breed?: string;
    Colour?: string;
    MicrochipNumber?: string;
    Sex?: string;
    Neutered?: boolean;
    Notes?: string;
    IsDeleted?: boolean;

    constructor(Dog: Dog) {
        this.ID = Dog.ID;
        this.Name = Dog.Name;
        this.Breed = Dog.Breed;
        this.Colour = Dog.Colour;
        this.MicrochipNumber = Dog.MicrochipNumber;
        this.Sex = Dog.Sex.toString();
        this.Neutered = Dog.Neutered;
        this.Notes = Dog.Notes;
        this.IsDeleted = Dog.IsDeleted;
    }
}

    import { Vue, Component, Prop } from 'vue-property-decorator'
    import api from '../utils/api'
    import PagedTAPRecordsDTO from '../DTOs/PagedTAPRecordsDTO'
    import Option from '../models/Option';
    import CurrentUser from '../utils/CurrentUser';
    import Location from '../models/Location';
    import PagedTAPRecordVM from '../viewmodels/TAPRecordVM';

    interface PagingData {
        page: number;
        itemsPerPage: number;
        sortBy: string[];
        sortDesc: string[];
        DogSearch: string;
        HandlerSearch: string;
        LocationSearch: string;
        TaskSearch: string;
        IncludeArchived: boolean | null;
    }

    interface FilterOptions {
        Locations: Location[];
        Tasks: Option[];
    }

    @Component({
        name: 'TAPRecords'
    })
    export default class TAPRecords extends Vue {
        select: string = '';
        loadingData: boolean = false;
        showError: boolean = false;
        loadingRecords: boolean = false;
        filtersChanged: boolean = false;
        totalRecords: number = 0;
        openedPanel: any = null;

        pagingData: PagingData = { page: 1, itemsPerPage: 20, sortBy: [], sortDesc: [], DogSearch: '', HandlerSearch: '', LocationSearch: '', TaskSearch: '', IncludeArchived: false };
        searchFields: any = { DogSearch: '', HandlerSearch: '', LocationSearch: '', TaskSearch: '', showArchived: false };

        filterOptions: FilterOptions = { Locations: [], Tasks: []};

        headers = [
            { text: 'Date & Time', value: 'Completed', filterable: false, width: '22%' },
            { text: 'Team', value: 'Team', sortable: false, width: '24%' },
            { text: 'Location', value: 'Location', width: '23%' },
            { text: 'Task', value: 'Task', width: '15%' },
            { text: 'Status', value: 'IsDeleted', width: '15%' },
        ];

        trainingRecords: PagedTAPRecordVM[] = [];

        async created() {
            this.loadingData = true;

            let trainingDropdownsResponse = await api.get<FilterOptions>('Training/PopulateDropdowns?userToken=' + CurrentUser.currentUserToken());

            if (trainingDropdownsResponse.ok) {
                this.filterOptions = trainingDropdownsResponse.data!;

                let filterRequired = false;
                let storedPagingData = localStorage.getItem("trainingRecordPagingData");
                let storedSearchFields = localStorage.getItem("trainingRecordSearchFields");

                if (storedPagingData) {
                    this.pagingData = JSON.parse(storedPagingData);
                    filterRequired = true;
                }

                if (storedSearchFields) {
                    this.searchFields = JSON.parse(storedSearchFields);
                    filterRequired = true;
                }
                if (filterRequired) {
                    this.openedPanel = 0;
                    this.searchRecords();
                }
            }
            else {
                this.showError = true;
            }

            this.loadingData = false;
        }

        async searchRecords() {
            this.savePagingData();

            this.loadRecords(this.pagingData);
            this.scrollToResults();
        }

        savePagingData() {
            localStorage.setItem("trainingRecordPagingData", JSON.stringify(this.pagingData));
            localStorage.setItem("trainingRecordSearchFields", JSON.stringify(this.searchFields));
        }

        async loadRecords(pagingDataEntry: PagingData ){
            this.loadingRecords = true;
            this.filtersChanged = false;
            this.pagingData = pagingDataEntry;

            this.pagingData.DogSearch = this.searchFields.DogSearch;
            this.pagingData.HandlerSearch = this.searchFields.HandlerSearch;
            this.pagingData.LocationSearch = this.searchFields.LocationSearch;
            this.pagingData.TaskSearch = this.searchFields.TaskSearch;
            this.pagingData.IncludeArchived = this.searchFields.showArchived ? this.searchFields.showArchived : false;

            let trainingRecordsResponse = await api.post<PagedTAPRecordsDTO>('Training/PagedTAPRecords?userToken=' + CurrentUser.currentUserToken(), this.pagingData);

            if (trainingRecordsResponse.ok) {
                this.trainingRecords = trainingRecordsResponse.data!.PagedTAPRecords;
                this.totalRecords = trainingRecordsResponse.data!.Total;
                this.loadingRecords = false;
            }
        }

        viewEditTrainingRecord(item: PagedTAPRecordVM) {
            this.$router.push('/TAPRecordDetails/' + item.ID);
        }

        createNewTrainingRecord(){
            this.$router.push('/TAPRecordDetails/0');
        }

        resetFilter() {
            Object.entries(this.searchFields).forEach(([key, val]) => {
              this.searchFields[key] = '';
            });

            localStorage.removeItem("trainingRecordPagingData");
            localStorage.removeItem("trainingRecordSearchFields");

            this.loadRecords(this.pagingData);
            this.scrollToResults();
        }

        getRowClass(item: PagedTAPRecordVM) {
            return item.IsDeleted ? 'archived-row' : '';
        }

        scrollToResults() {
            this.$nextTick(() => {
                const el = this.$el.querySelector(".v-data-table:first-of-type");
                const yOffset = -88;
                const y = el!.getBoundingClientRect().top + window.pageYOffset + yOffset;

                window.scrollTo({ top: y, behavior: 'smooth' });

                return;
            });
        }
    }

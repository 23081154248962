import Dog from "../models/Dog";
import DogHandlerTeam from "../models/DogHandlerTeam";
import User from "../models/User";

export const Rules = {
    requiredField: [
        (value: string) => !!value || 'Required'
    ],

    requiredFieldWithId: [
        (value: Dog | User) => (!value || value.ID != 0) || 'Required'
    ],

    numericField: [
        (value: string) => (!value || /^-?\d+$/.test(value)) || 'Number required'
    ],

    requiredNumericField: [
        (value: string) => (/^-?\d+$/.test(value)) || 'Required'
    ],

    requiredFieldObj: [
        (v: any) => Boolean(Object.keys(v || {})[0]) || "Field is required"
    ],

    teamRequiredField: [
        (value: DogHandlerTeam) => (value && (value.Dog.Name != "" || value.Handler.FullName != "")) || 'Required'
    ],

    emailRules: [
        (value: string) => (!!value) || 'Required',
        (value: string) => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail format'
        }
    ]
}
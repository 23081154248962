
    import { Vue, Component, Prop } from 'vue-property-decorator'
    import router from '../router/index'
    import api from '../utils/api';
    import CurrentUser from '../utils/CurrentUser'
    import { TrainingRecordsForApprovalVM } from '../viewmodels/TrainingRecordVM';

    @Component({
        name: 'Home'
    })
    export default class Home extends Vue {
        loadingData: boolean = false;
        showError: boolean = false;

        recordsForApproval: TrainingRecordsForApprovalVM[] = [];

        pages = [
            { colour: '187, 133, 136', margin: 0, text: 'Continuation Training Records', shortText: 'Continuation Training Records', icon: 'mdi-clipboard-edit', description: 'View, edit, submit or approve existing CT Records, or create a new Record.', outstandingItems: this.recordsForApproval.length, location: '/TrainingRecords', permission: 'ViewTrainingRecords' },
            { colour: '171, 146, 191', margin: 0, text: 'Training Aid Placement Records', shortText: 'TAP Records', icon: 'mdi-clipboard-edit-outline', description: 'View, edit or create new Training Aid Placement Records.', outstandingItems: '', location: '/TAPRecords', permission: 'ViewTrainingRecords' },
            { colour: '175, 193, 214', margin: 0, text: 'Dog Profiles', shortText: 'Dog Profiles', icon: 'mdi-dog-side', description: 'View, edit, or create new Dog Profiles.', outstandingItems: '', location: '/DogProfiles', permission: '' },
            { colour: '129, 166, 132', margin: -12, text: 'Handler Profiles', shortText: 'Handler Profiles', icon: 'mdi-account-multiple', description: 'View and edit Handler Profiles.', outstandingItems: '', location: '/HandlerProfiles', permission: 'ViewHandlers' },
            { colour: '190, 178, 200', margin: 12, text: 'Admin', shortText: 'Admin', icon: 'mdi-cogs', description: 'Manage site admin - view or edit dropdowns, locations, users and teams or run reports.', outstandingItems: '', location: '/Admin', permission: 'ViewAdmin' }
        ];

        openCTA(id: number) {
            router.push('/TrainingRecordDetails/' + id);
        }

        checkPermission(permission: string) {
            return CurrentUser.hasPermission(permission);
        }

        async created() {
            this.loadingData = true;
            // Get records for approval
            let recordForApprovalResponse = await api.get<TrainingRecordsForApprovalVM[]>('Training/RecordsForApproval?userToken=' + CurrentUser.currentUserToken());

            if (recordForApprovalResponse.ok) {
                this.recordsForApproval = recordForApprovalResponse.data!;
                let trainingRecordPage = this.pages.find(p => p.location == '/TrainingRecords');
                trainingRecordPage!.outstandingItems = this.recordsForApproval.length;
            }
            else {
                this.showError = true;
            }

            this.loadingData = false;

            if (CurrentUser.firstLogin()) {
                (this.$root.$children[0] as any).changePasswordDialog = true;
                // Reset firstLogin to prevent this popup constantly appearing if the user does not change password
                CurrentUser.resetLogin();
            }            
        }
    }

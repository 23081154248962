
    import { Vue, Component, Prop } from 'vue-property-decorator'
    import TrainingRecordDetailDTO from '../DTOs/TrainingRecordDetailDTO';
    import api from '../utils/api'
    import Option from '../models/Option'
    import DateTimeUtil from '../utils/DateTimeUtil'
    import { Sex } from '../models/Dog'
    import router from '../router';
    import DogHandlerTeam from '../models/DogHandlerTeam';
    import TrainingSubstanceDetection from '../models/TrainingSubstanceDetection'
    import TrainingRecord from '../models/TrainingRecord'
    import CurrentUser from '../utils/CurrentUser';
    import Location from '../models/Location';
    import FileVM from '../viewmodels/FileVM';
    import FileHelper from '../utils/FileHelper';
    import MultiSelectUtil from '../utils/MultiSelectUtil';
    import $ from "jquery";
    import { Rules } from '../utils/Rules';

    @Component({
        name: 'TrainingRecord',
        beforeRouteLeave(to, from, next) {
            const self = this as any;
            // Check for unsaved changes
            let originalCompleted = self.TrainingRecord.Completed ? DateTimeUtil.splitDate(new Date(self.TrainingRecord.Completed)) : [null, null];
            let durationMinutes = DateTimeUtil.convertToMinutes(self.duration.Hours, self.duration.Minutes);

            if (!self.saveSubmitCancel && (self.OriginalTrainingRecord) && (
                (JSON.stringify(self.TrainingRecord) !== self.OriginalTrainingRecord) ||
                (originalCompleted[0] != self.date || originalCompleted[1] != self.time) ||
                (self.TrainingRecord.DurationMinutes != durationMinutes)
            )) {
                self.next = next;
                self.confirmLeaveModal = true;
            }
            else {
                next();
            }
        }
    })
    export default class TrainingRecordDetails extends Vue {
        @Prop(Number) id!: number;
        next: any;
        saveSubmitCancel = false;

        editMode: boolean = false;

        activePicker: any = null;
        date: Date | null | string = null;
        dateModal: boolean = false;
        timeModal: boolean = false;
        time: string | null = null;
        Sexes = [Sex.Male, Sex.Female];

        loadingData: boolean = false;
        savingData: boolean = false;
        uploadingFiles: boolean = false;
        showError: boolean = false;
        showErrorMessage: boolean = false;
        errorText: string = 'An error occurred';
        durationError: boolean = false;
        showSaveSuccess: boolean = false;
        confirmDeleteModal: boolean = false;
        confirmArchiveRestoreModal: boolean = false;
        confirmDeleteFileModal: boolean = false;
        confirmLeaveModal: boolean = false;
        valid: boolean = true;
        showSignatureRequired: boolean = false;
        submittingTrainerSig: boolean = false;
        submittingHandlerSig: boolean = false;
        fab: boolean = false;
        fileForDeletion: FileVM | null = null;
        duration = {Hours: 0, Minutes: 0};
        weatherItems: number = 0;
        Rules: object = Rules;

        teamRequiredField: (Function)[] = [];
        numericField: (Function)[] = Rules.numericField;
        requiredField: (Function)[] = [];
        requiredFieldObj: (Function)[] = [];

        setFieldValidation(submitting: boolean) {
            this.teamRequiredField = submitting ? Rules.teamRequiredField : [];
            this.numericField = submitting ? Rules.requiredNumericField : Rules.numericField;
            this.requiredField = submitting ? Rules.requiredField : [];
            this.requiredFieldObj = submitting ? Rules.requiredFieldObj : [];
        }

        hours = [0];
        minutes = [0];

        headers = [
            { text: 'Name', value: 'Name', sortable: false },
            { text: 'File Type', value: 'FileType', sortable: false },
            { text: 'Uploaded', value: 'Uploaded', sortable: false },
            { text: '', value: 'Download', sortable: false, width: '6%' },
            { text: '', value: 'Delete', sortable: false, width: '6%' },
        ];

        // file-input only stores last added files
        lastAddedFiles: File[] = [];
        fileVMs: FileVM[] = [];

        selectedSubstanceDetectionFiles: FileVM[] | null = null;
        substanceDetectionLastAddedFiles: File[] = [];

        TrainingRecord: TrainingRecord = {
            ID: 0, Completed: undefined, Location: undefined, Type: undefined, Task: undefined, DogHandlerTeam: undefined,
            Trainer: undefined, Temperature: undefined, Weather: [], Goal: '', TrainerObservation: '', HandlerComment: '', TrainerApproved: null, HandlerApproved: null,
            TrainerSignatureUploaded: false, HandlerSignatureUploaded: false, IsDeleted: false, SubstanceDetections: [], DevelopmentNeeds: '', Status: '', DurationMinutes: 0,
            Indication: undefined, Modified: null, ModifiedBy: null
        };

        OriginalTrainingRecord?: string;

        TrainingLocations: Location[] = [];
        TrainingTypes: Option[] = [];
        TrainingTasks: Option[] = [];
        TrainingIndications: Option[] = [];
        Substances: Option[] = [];
        SubstanceDetectionTypes: Option[] = [];
        SubstancePackaging: Option[] = [];
        WeatherConditions: Option[] = [];
        AccreditationCompletion: number | null = null;

        trainerSignature: string = '';
        handlerSignature: string = '';

        substanceDetectionToRemove: TrainingSubstanceDetection | null = null;

        DogHandlerTeams: DogHandlerTeam[] = [];

        Exit() {
            this.next();
        }

        async created() {
            this.loadingData = true;
            for (let i = 1; i <= 8; i++) {this.hours.push(i)};
            for (let i = 5; i < 60; i = i + 5) {this.minutes.push(i)};

            let trainingRecordDetailResponse = await api.get<TrainingRecordDetailDTO>('Training/TrainingRecordDetail?id=' + this.id + '&userToken=' + CurrentUser.currentUserToken());

            if (trainingRecordDetailResponse.ok) {

                let responseData = trainingRecordDetailResponse.data;
                this.TrainingLocations = responseData!.LocationOptions;
                this.TrainingTypes = responseData!.TrainingTypeOptions;
                this.TrainingTasks = responseData!.TrainingTaskOptions;
                this.TrainingIndications = responseData!.TrainingIndicationOptions;
                this.Substances = responseData!.SubstanceOptions;
                this.SubstanceDetectionTypes = responseData!.SubstanceTypeOptions;
                this.SubstancePackaging = responseData!.SubstancePackagingOptions;
                this.WeatherConditions = responseData!.WeatherOptions;
                this.DogHandlerTeams = responseData!.DogHandlerTeams;
                if (responseData!.Documents) this.fileVMs = responseData!.Documents;

                if (responseData!.TrainingMinutesSinceAccreditation) this.AccreditationCompletion = responseData!.TrainingMinutesSinceAccreditation / (60);

                if (this.id != 0) {
                    this.TrainingRecord = responseData!.TrainingRecord;
                    if (this.TrainingRecord.Completed) {
                        //DateTime fields
                        [this.date, this.time] = DateTimeUtil.splitDate(new Date(this.TrainingRecord.Completed!));
                    }
                    if (this.TrainingRecord.DurationMinutes) {
                        //Duration fields
                        [this.duration.Hours, this.duration.Minutes] = DateTimeUtil.splitTimeMinutes(this.TrainingRecord.DurationMinutes!);
                    }
                }

                // Handlers can only view their own training records
                if (CurrentUser.currentUserRole() == "Handler" && this.TrainingRecord.DogHandlerTeam?.Handler.ID != CurrentUser.currentUserID()) {
                    this.$router.back();
                }

                this.editMode = CurrentUser.hasPermission('EditTrainingRecords') && (!this.TrainingRecord.Trainer || this.TrainingRecord.Trainer.ID == CurrentUser.currentUserID()) && !this.TrainingRecord.TrainerApproved && !this.TrainingRecord.IsDeleted;

                if (this.TrainingRecord.TrainerSignatureUploaded) { 
                    let trainerSignatureResponse = await api.getFile<any>('Training/GetSignature?TrainingRecordID=' + this.id + '&SignatureType=TrainerSignature' + '&userToken=' + CurrentUser.currentUserToken());

                    if (trainerSignatureResponse.ok) {
                        this.trainerSignature = URL.createObjectURL(trainerSignatureResponse.data!);
                    }
                    else {
                        // what should happen?
                        this.showError = true;
                    }
                }

                if (this.TrainingRecord.HandlerSignatureUploaded) {
                    let handlerSignatureResponse = await api.getFile<any>('Training/GetSignature?TrainingRecordID=' + this.id + '&SignatureType=HandlerSignature' + '&userToken=' + CurrentUser.currentUserToken());

                    if (handlerSignatureResponse.ok) {
                        this.handlerSignature = URL.createObjectURL(handlerSignatureResponse.data!);
                    }
                    else {
                        // what should happen?
                        this.showError = true;
                    }
                }

                this.OriginalTrainingRecord = JSON.stringify(this.TrainingRecord);
            }
            else {
                this.showError = true;
            }

            this.loadingData = false;
        }

        durationInputOnFocus() {
            $('.duration-input').addClass('v-input--is-focused');
            $('.duration-input').addClass('primary--text');
            $('.duration-input .v-icon').addClass('primary--text');
        }

        durationInputOnUnfocus() {
            $('.duration-input').removeClass('v-input--is-focused');
            $('.duration-input').removeClass('primary--text');
            $('.duration-input .v-icon').removeClass('primary--text');
        }

        updateTrainingRecordIDs(returnedTrainingRecord: TrainingRecord) {
            for (var i = 0; i < this.TrainingRecord.SubstanceDetections.length; i++) {
                this.TrainingRecord.SubstanceDetections[i].ID = returnedTrainingRecord.SubstanceDetections[i].ID;
            }
            this.TrainingRecord.ID = returnedTrainingRecord.ID;
        }

        async saveTrainingRecord() : Promise<boolean> {
            (this.$refs!.form! as any).resetValidation();

            // For some reason setTimeout is required for the validation to update
            let self = this;
            setTimeout(async function () {
                self.setFieldValidation(false);

                setTimeout(async function () {

                    (self.$refs!.form! as any).validate();

                    if (self.valid) {
                        self.savingData = true;
                        self.TrainingRecord.Completed = (self.date && self.time) ? DateTimeUtil.createDateTime(self.date! as string, self.time!) : undefined;
                        if (self.TrainingRecord.TrainerApproved) self.TrainingRecord.TrainerApproved = new Date(self.TrainingRecord.TrainerApproved!);
                        if (self.TrainingRecord.HandlerApproved) self.TrainingRecord.HandlerApproved = new Date(self.TrainingRecord.HandlerApproved!);
                        self.TrainingRecord.DurationMinutes = DateTimeUtil.convertToMinutes(self.duration.Hours, self.duration.Minutes);
                        let trainingRecordUploadResponse = await api.post<TrainingRecord>('Training/SaveTrainingRecord?userToken=' + CurrentUser.currentUserToken(), self.TrainingRecord);

                        if (trainingRecordUploadResponse.ok) {
                            self.updateTrainingRecordIDs(trainingRecordUploadResponse.data!);

                            // Upload files
                            self.uploadingFiles = true;
                            let addDeleteFilesResponse = await FileHelper.addDeleteFiles(self.fileVMs, "TBL_TrainingRecord", self.TrainingRecord.ID);
                            self.fileVMs = addDeleteFilesResponse.returnArray;

                            // Upload SD files
                            let addDeleteSubstanceDetectionFiles = await FileHelper.addDeleteSubstanceDetectionFiles(self.TrainingRecord.SubstanceDetections);

                            self.TrainingRecord.SubstanceDetections = addDeleteSubstanceDetectionFiles.returnArray;

                            self.uploadingFiles = false;
                            self.showSaveSuccess = addDeleteFilesResponse.success && addDeleteSubstanceDetectionFiles.success;

                            // Save Signatures
                            self.uploadSignature();

                            self.saveSubmitCancel = true;
                            self.$router.push('/TrainingRecords');
                        }
                        else {
                            self.showErrorMessage = true;
                            self.errorText = "Error: " + (trainingRecordUploadResponse.error ?? "Could not save Training Record");
                        }

                        self.savingData = false;
                    }
                    else {
                        self.scrollToInvalidField();
                    }
                });
            });
            return this.valid;
        }

        scrollToInvalidField() {
            this.$nextTick(() => {
                const el = this.$el.querySelector(".v-messages.error--text:first-of-type");
                const yOffset = -200;
                const y = el!.getBoundingClientRect().top + window.pageYOffset + yOffset;

                window.scrollTo({ top: y, behavior: 'smooth' });

                return;
            });
        }

        durationValidation(){
            if ((this.duration.Hours != 0 || this.duration.Minutes != 0) && !(this.duration.Hours == 8 && this.duration.Minutes > 0)){
                this.durationError = false;
            }
            else {
                this.durationError = true;
            }
        }

        async uploadSignature() {
            if (CurrentUser.currentUserRole() == "Handler" && this.TrainingRecord.TrainerApproved) {
                if ((this.$refs.handlerSignaturePad! as any) && !(this.$refs.handlerSignaturePad! as any).isEmpty()) {
                    // save signature
                    this.savingData = true;
                    this.submittingHandlerSig = true;
                    // data is base64 string
                    var { isEmpty, data } = (this.$refs.handlerSignaturePad! as any).saveSignature();
                    let success = this.saveSignature(data, "HandlerSignature");
                }
            }
            else if (CurrentUser.currentUserRole() == "Admin") {
                if ((this.$refs.trainerSignaturePad! as any) && !(this.$refs.trainerSignaturePad! as any).isEmpty()) {
                    // save signature
                    this.savingData = true;
                    this.submittingTrainerSig = true;
                    // data is base64 string
                    var { isEmpty, data } = (this.$refs.trainerSignaturePad! as any).saveSignature();
                    let success = this.saveSignature(data, "TrainerSignature");
                }
            }
        }

        async submitTrainingRecord() {
            this.setFieldValidation(true);            
            this.durationValidation();

            let self = this;

            // For some reason setTimeout is required for the validation to update
            setTimeout(function () {
                (self.$refs!.form! as any).validate();

                if (self.valid && !self.durationError) {
                    // check for signature
                    if (CurrentUser.currentUserRole() == "Handler") {
                        if (self.TrainingRecord.TrainerApproved) {
                            if (!self.TrainingRecord.HandlerSignatureUploaded && (self.$refs.handlerSignaturePad! as any).isEmpty()) {
                                self.showSignatureRequired = true;
                            }
                            else {
                                // save
                                self.savingData = true;

                                self.TrainingRecord.HandlerApproved = new Date();
                                self.saveTrainingRecord();
                            }
                        }
                        else {
                            // Shouldnt have been enabled
                        }
                    }
                    else if (CurrentUser.currentUserRole() == "Admin") {
                        if (!self.TrainingRecord.TrainerSignatureUploaded && (self.$refs.trainerSignaturePad! as any).isEmpty()) {
                            self.showSignatureRequired = true;
                        }
                        else {
                            // save
                            self.savingData = true;

                            self.TrainingRecord.TrainerApproved = new Date();
                            self.saveTrainingRecord();
                        }
                    }
                }
                else {
                    self.scrollToInvalidField();
                }
            });
        }

        setupPositiveBlankChanged(e : TrainingSubstanceDetection) {
            // reset fields
            if (e.SetupPositiveBlank == 'Blank') {
                e.SetupVisualHidden = '';
                e.Substance = undefined;
                e.Amount = undefined;
                e.Packaging = undefined;
                e.Type = undefined;
                e.AtHeight = false;
                e.HideLocation = '';
            }
        }

        async saveSignature(file: string, signatureType: string): Promise<boolean> {

            let signatureUploadResponse = await api.post<null>('Training/UploadSignature?userToken=' + CurrentUser.currentUserToken(), { FileBase64string: file, TrainingRecordID: this.TrainingRecord.ID, FileName: signatureType + ".png" });

            return signatureUploadResponse.ok;
        }

        addNewSubstanceDetection() {
            let newSubstanceDetection: TrainingSubstanceDetection = {ID: 0, Amount: undefined, HideLocation: '', Observations: '', Substance: undefined, Type: undefined, AtHeight: false, Documents: [], 
                SetupKnownBlind: '', SetupOnOffLead: '', SetupPositiveBlank: '',SetupVisualHidden: '', };
            this.TrainingRecord.SubstanceDetections.push(newSubstanceDetection);
        }

        removeSubstanceDetection(substanceDetection: TrainingSubstanceDetection) {
            this.confirmDeleteModal = true;
            this.substanceDetectionToRemove = substanceDetection;
        }

        confirmRemoveSubstanceDetection() {
            if (this.substanceDetectionToRemove) {
                const index = this.TrainingRecord.SubstanceDetections.indexOf(this.substanceDetectionToRemove);
                if (index > -1) {
                    this.TrainingRecord.SubstanceDetections.splice(index, 1);
                }
                this.substanceDetectionToRemove = null;
            }
            this.confirmDeleteModal = false;
        }

        undoTrainerSig() {
            (this.$refs.trainerSignaturePad! as any).undoSignature();
        }

        undoHandlerSig() {
            (this.$refs.handlerSignaturePad! as any).undoSignature();
        }

        decodeHtml(html: string) {
            var txt = document.createElement("textarea");
            txt.innerHTML = html;
            return txt.value;
        }

        async archiveRestoreRecord() {
            this.savingData = true;
            this.TrainingRecord.IsDeleted = !this.TrainingRecord.IsDeleted;
            let trainingRecordUploadResponse = await api.post<null>('Training/SaveTrainingRecord?userToken=' + CurrentUser.currentUserToken(), this.TrainingRecord);

            if (trainingRecordUploadResponse.ok) {
                this.showSaveSuccess = true;
            }
            else {
                this.showErrorMessage = true;
                this.errorText = "Error: " + (trainingRecordUploadResponse.error ?? "Could not archive Training Record");
            }
            this.savingData = false;
            this.confirmArchiveRestoreModal = false;
            this.editMode = CurrentUser.hasPermission('EditTrainingRecords') && !this.TrainingRecord.TrainerApproved && !this.TrainingRecord.IsDeleted;
        }

        goBack() {
            this.saveSubmitCancel = true;
            router.back();
        }

        onScroll(e: any) {
            if (typeof window === 'undefined') return
            const top = window.pageYOffset || e.target.scrollTop || 0
            this.fab = top > 20
        }

        toTop() {
            this.$vuetify.goTo(0);
        }

        addFile() {
            for (let i = 0; i < this.lastAddedFiles.length; i++) {
                let file = (this.lastAddedFiles[i] as File);
                this.fileVMs.push({ ID: 0, Name: file.name, FileType: file.type.split('/').pop() || '', Uploaded: new Date, ExternalFileName: '', InternalFileName: '', IsDeleted: false, File: file });
            }
        }

        confirmDeleteFile(file: FileVM) {
            this.fileForDeletion = file;
            this.confirmDeleteFileModal = true;
        }

        async downloadFile(fileInfo: FileVM) {
            let file = await api.DownloadFile(fileInfo.ID);
            if (file) {
                const link = document.createElement('a');
                link.href = file;
                link.download = fileInfo.ExternalFileName;
                link.click();
            }
            else {
                this.showErrorMessage = true;
                this.errorText = "Error: Could not download file";
            }
        }

        async deleteFile() {
            let fileInfo = this.fileForDeletion;
            if (fileInfo) {
                fileInfo.IsDeleted = true;
            }
            this.confirmDeleteFileModal = false;
        }

        addSubstanceDetectionFileClick(substanceDetectionFiles: FileVM[]) {
            // Store the specific substance detection files view model so we know which SD to store the files against when they are uploaded
            this.selectedSubstanceDetectionFiles = substanceDetectionFiles;
            
            (this.$refs.SubstanceDetectionDocumentUpload! as any).$refs.input.click();
        }

        addSubstanceDetectionFile() {
            if (this.selectedSubstanceDetectionFiles) {
                for (let i = 0; i < this.substanceDetectionLastAddedFiles.length; i++) {
                    let file = (this.substanceDetectionLastAddedFiles[i] as File);
                    this.selectedSubstanceDetectionFiles.push({ ID: 0, Name: file.name, FileType: file.type.split('/').pop() || '', Uploaded: new Date, ExternalFileName: '', InternalFileName: '', IsDeleted: false, File: file });
                }
            }
        }

        isTrainer() : boolean {
            return CurrentUser.currentUserRole() == "Admin";
        }

        isHandler() : boolean {
            return CurrentUser.currentUserRole() == "Handler";
        }

        mounted() {
            this.$nextTick(() => {
                window.addEventListener('resize', this.detectResizeForWeatherSelect);
                window.addEventListener('load', this.detectResizeForWeatherSelect);
            });
        }

        updated() {
            this.detectResizeForWeatherSelect();
        }

        detectResizeForWeatherSelect() {
            if (this.$refs.weatherMultiSelect) {
                this.weatherItems = MultiSelectUtil.numberOfItems(this.$refs.weatherMultiSelect);
            }
        }

        checkPermission(permission: string) {
            return CurrentUser.hasPermission(permission);
        }

        setActiveClass(e: any) {
            $("#durationInput").toggleClass('v-input--is-focused');
            $("#durationInput").toggleClass('primary--text');
        }
    }

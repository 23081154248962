import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import DogProfileDetails from '../views/DogProfileDetails.vue'
import DogProfiles from '../views/DogProfiles.vue'
import HandlerProfiles from '../views/HandlerProfiles.vue'
import HandlerProfileDetails from '../views/HandlerProfileDetails.vue'
import TrainingRecords from '../views/TrainingRecords.vue'
import TrainingRecordDetails from '../views/TrainingRecordDetails.vue'
import TAPRecords from '../views/TAPRecords.vue'
import TAPRecordDetails from '../views/TAPRecordDetails.vue'
import Admin from '../views/Admin.vue'
import Login from '../views/Login.vue'

import Home from '../views/Home.vue'
import ManageDropdowns from '../views/Admin/ManageDropdowns.vue'
import ManageTeams from '../views/Admin/ManageTeams.vue'
import ManageLocations from '../views/Admin/ManageLocations.vue'
import ManageUsers from '../views/Admin/ManageUsers.vue'
import Reports from '../views/Admin/Reports.vue'
import CurrentUser from '../utils/CurrentUser'
import ForgotPassword from '../views/ForgotPassword.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    { path: '/', name: 'Home', component: Home },
    { path: '/DogProfiles', name: 'DogProfiles', component: DogProfiles },
    { path: '/HandlerProfiles', name: 'HandlerProfiles', component: HandlerProfiles },
    { path: '/TrainingRecords', name: 'TrainingRecords', component: TrainingRecords },
    { path: '/TAPRecords', name: 'TAPRecords', component: TAPRecords },
    { path: '/DogProfileDetails/:id', name: 'DogProfileDetails', component: DogProfileDetails, props: route => ({ id: parseInt(route.params.id) }) },
    { path: '/HandlerProfileDetails/:id', name: 'HandlerProfileDetails', component: HandlerProfileDetails, props: route => ({ id: parseInt(route.params.id) }) },
    { path: '/TrainingRecordDetails/:id', name: 'TrainingRecordDetails', component: TrainingRecordDetails, props: route => ({ id: parseInt(route.params.id) }) },
    { path: '/TAPRecordDetails/:id', name: 'TAPRecordDetails', component: TAPRecordDetails, props: route => ({ id: parseInt(route.params.id) }) },
    { path: '/Admin', name: 'Admin', component: Admin },
    { path: '/Admin/ManageDropdowns', name: 'ManageDropdowns', component: ManageDropdowns },
    { path: '/Admin/ManageTeams', name: 'ManageTeams', component: ManageTeams },
    { path: '/Admin/ManageLocations', name: 'ManageLocations', component: ManageLocations },
    { path: '/Admin/ManageUsers', name: 'ManageUsers', component: ManageUsers },
    { path: '/Admin/Reports', name: 'Reports', component: Reports },
    { path: '/Login', name: 'Login', component: Login, meta: { hideNavbar: true, } },
    { path: '/ForgotPassword', name: 'ForgotPassword', component: ForgotPassword, meta: { hideNavbar: true, } },
]

const router = new VueRouter({
  mode: 'history',
    base: '/',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        else {
            return { x: 0, y: 0 }
        }
    },
})




// check for user token in storage, else return to home
router.beforeEach((to, from, next) => {
    if (to.name != 'Login' && to.name != 'ForgotPassword' && !localStorage.getItem('CurrentUser')) {
        next({
            path: 'login',
            query: {
                redirect: to.fullPath,
            }
        });
    }
    else {
        let permission = false;
        switch (to.name) {
            case 'Home':
            case 'Login':
            case 'ForgotPassword':
            case 'DogProfiles':
            case 'DogProfileDetails':
                permission = true;
                break;
            case 'HandlerProfiles':
                permission = CurrentUser.hasPermission('ViewHandlers');
                break;
            case 'HandlerProfileDetails':
                permission = CurrentUser.hasPermission('EditAllUsers') || (CurrentUser.currentUserRole() == 'Handler' && to.params.id == CurrentUser.currentUserID().toString())
                break;
            case 'TrainingRecords':
            case 'TAPRecords':
            case 'TrainingRecordDetails':
            case 'TAPRecordDetails':
                permission = CurrentUser.hasPermission('ViewTrainingRecords');
                break;
            case 'Admin':
            case 'ManageDropdowns':
            case 'ManageTeams':
            case 'ManageLocations':
            case 'ManageUsers':
            case 'Reports':
                permission = CurrentUser.hasPermission('ViewAdmin');
                break;
            default:
                break;
        }
        if (permission) next();
        else next('/');
    }
})

export default router

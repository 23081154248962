import LoginResponse from '../models/LoginResponse'

export default {
    hasPermission(permission: string) {
        if (!permission) return true;
        let currentUser = localStorage.getItem('CurrentUser')!;
        let userRole = (JSON.parse(currentUser) as LoginResponse).UserRole;
        let userPermissions = (JSON.parse(currentUser) as LoginResponse).Permissions;

        return (userRole == "Master" || userPermissions?.includes(permission));
    },

    currentUserRole() {
        return this.currentUser().UserRole;
    },

    currentUser() : LoginResponse {
        let currentUser = localStorage.getItem('CurrentUser')!;
        return (JSON.parse(currentUser) as LoginResponse);
    },

    currentUserToken() {
        return this.currentUser().UserToken;
    },

    currentUserID() {
        return this.currentUser().UserID;
    },

    firstLogin(): boolean {
        return this.currentUser()?.FirstLogin ?? false;
    },

    resetLogin() {
        let currentUser = this.currentUser();
        currentUser.FirstLogin = false;
        localStorage.setItem('CurrentUser', JSON.stringify(currentUser));
    }
}
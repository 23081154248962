
    import { Vue, Component, Prop } from 'vue-property-decorator'
    import UserProfilesDTO from '../../DTOs/UserProfilesDTO';
    import User from '../../models/User';
    import UserRole from '../../models/UserRole';
    import api from '../../utils/api'
    import CurrentUser from '../../utils/CurrentUser';
    import { Rules } from '../../utils/Rules';
    @Component({
        name: 'UserProfiles'
    })
    export default class UserProfiles extends Vue {
        search: string = '';
        select: string = '';
        loadingData: boolean = false;
        showError: boolean = false;
        showSaveSuccess: boolean = false;
        showErrorMessage: boolean = false;
        savingData: boolean = false;
        valid: boolean = true;
        Rules: object = Rules;
        addEditUserDialog: boolean = false;
        errorText: string = 'An error occurred';
        showArchived: boolean = false;

        headers = [
            { text: 'First Name', value: 'FirstName', width:'22%' },
            { text: 'Surname', value: 'Surname', width: '20%' },
            { text: 'Email', value: 'Email', filterable: false, width: '20%' },
            { text: 'User Role', value: 'UserRole.RoleName', filterable: false, width: '18%' },
            { text: 'Status', value: 'IsDeleted', filterable: false, width: '20%' },
        ];

        selectedUser: User = { ID: 0, FirstName: '', Surname: '', FullName: '', SIANumber: '', Email: '', UserRole: undefined, IsDeleted: false };
        userProfiles: User[] = [];
        userProfilesFiltered: User[] = [];
        userRoles: UserRole[] = [];

        async created() {
            this.loadingData = true;
            let userProfileResponse = await api.get<UserProfilesDTO>('User/AllUsers?userToken=' + CurrentUser.currentUserToken());

            if (userProfileResponse.ok) {
                this.userProfiles = userProfileResponse.data!.Users;
                this.userProfilesFiltered = userProfileResponse.data!.Users.filter(d => !d.IsDeleted);
                this.userRoles = userProfileResponse.data!.UserRoles;
            }
            else{
                this.showError = true;
            }
            this.loadingData = false;
        }

        filterUserProfiles() {
            if (this.showArchived) {
                this.userProfilesFiltered = this.userProfiles;
            }
            else {
                this.userProfilesFiltered = this.userProfiles.filter(d => !d.IsDeleted)
            }
        }

        viewEditUserProfile(item: User) {
            this.selectedUser = Object.assign({}, item);
            this.addEditUserDialog = true;
        }

        createNewUserProfile() {
            this.selectedUser = { ID: 0, FirstName: '', Surname: '', FullName: '', SIANumber: '', Email: '', UserRole: undefined, IsDeleted: false };
            this.addEditUserDialog = true;
        }

        async addUpdateUser() {
            (this.$refs!.form! as any).validate();

            if (this.valid) {
                this.savingData = true;

                let userUploadResponse = await api.post<User>('User/SaveUserDetails?userToken=' + CurrentUser.currentUserToken(), this.selectedUser);

                if (userUploadResponse.ok) {
                    let returnedUser = userUploadResponse.data!;

                    if (this.selectedUser.ID == 0) {
                        this.userProfiles.push(returnedUser);
                    }
                    else {
                        this.userProfiles = this.userProfiles.map(u => u.ID !== returnedUser.ID ? u : returnedUser);
                    }

                    this.filterUserProfiles();
                    this.addEditUserDialog = false;
                    this.showSaveSuccess = true;
                }
                else {
                    // error
                    this.showErrorMessage = true;
                    this.errorText = "Error: " + (userUploadResponse.error ?? "Could not save User Profile");
                }
                
                this.savingData = false;
            }
        }

        archiveRestoreUser() {
            this.selectedUser.IsDeleted = !this.selectedUser.IsDeleted;
            this.addUpdateUser();
        }

        getRowClass(item: User) {
            return item.IsDeleted ? 'archived-row' : '';
        }
    }

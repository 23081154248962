import TrainingSubstanceDetection from "../models/TrainingSubstanceDetection";
import FileVM from "../viewmodels/FileVM";
import api, { ApiResponse } from "./api";
import CurrentUser from "./CurrentUser";

export interface AddDeleteFilesResponse {
    success: boolean;
    returnArray: any[];
}

export default {
    async addDeleteFiles(allFiles: FileVM[], linkTable: string, linkTableID: number): Promise<AddDeleteFilesResponse> {
        var success = true;

        for (var i = 0; i < allFiles.length; i++) {
            if (allFiles[i].ID == 0 && !allFiles[i].IsDeleted && allFiles[i].File) {
                let uploadFileResponse = await api.postWithFiles<any>('File/UploadFile?userToken=' + CurrentUser.currentUserToken() + '&linkTable=' + linkTable + '&linkTableId=' + linkTableID, allFiles[i].File);

                if (uploadFileResponse.ok) {
                    // Re-add files now with an ID
                    allFiles[i] = uploadFileResponse.data!;
                }
                else {
                    success = false;
                }
            }
            else if (allFiles[i].ID != 0 && allFiles[i].IsDeleted) {
                await api.delete('File/DeleteFile?userToken=' + CurrentUser.currentUserToken() + '&ID=' + allFiles[i].ID);
            }
        }

        const r: AddDeleteFilesResponse = {
            success: success,
            returnArray: allFiles
        };

        return r;
    },

    async addDeleteSubstanceDetectionFiles(substanceDetections: TrainingSubstanceDetection[]): Promise<AddDeleteFilesResponse>  {
        var success = true;

        for (var i = 0; i < substanceDetections.length; i++) {
            let substanceDetection = substanceDetections[i];

            if (substanceDetection.Documents.length > 0) {
                for (var ii = 0; ii < substanceDetection.Documents.length; ii++) {
                    if (substanceDetection.Documents[ii].ID == 0 && substanceDetection.Documents[ii].File && !substanceDetection.Documents[ii].IsDeleted) {
                        let uploadFileResponse = await api.postWithFiles<any>('File/UploadFile?userToken=' + CurrentUser.currentUserToken() + '&linkTable=TBL_TrainingSubstanceDetection&linkTableId=' + substanceDetection.ID, substanceDetection.Documents[ii].File);

                        if (uploadFileResponse.ok) {
                            // Re-add files now with an ID
                            substanceDetection.Documents[ii] = uploadFileResponse.data!;
                        }
                        else {
                            success = false;
                        }
                    }
                    else if (substanceDetection.Documents[ii].ID != 0 && substanceDetection.Documents[ii].IsDeleted) {
                        await api.delete('File/DeleteFile?userToken=' + CurrentUser.currentUserToken() + '&ID=' + substanceDetection.Documents[ii].ID);
                    }
                }
            }
        }

        const r: AddDeleteFilesResponse = {
            success: success,
            returnArray: substanceDetections
        };

        return r;
    }

}
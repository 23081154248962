
    import { Vue, Component, Prop } from 'vue-property-decorator'

    @Component({
        name: 'Admin'
    })
    export default class Admin extends Vue {
        pages = [
            { colour: '187, 133, 136', margin: -12, text: 'Manager Users', icon: 'mdi-account-multiple', description: 'View or edit existing users, or add a new user.', location: '/Admin/ManageUsers'},
            { colour: '171, 146, 191', margin: -8, text: 'Manage Dropdowns', icon: 'mdi-table-edit', description: 'View, edit or add new dropdown options for Training Records and Substance Detections.', location: '/Admin/ManageDropdowns' },
            { colour: '175, 193, 214', margin: 0, text: 'Manage Locations', icon: 'mdi-map-marker-multiple-outline', description: 'View or edit existing locations, or add a new location.', location: '/Admin/ManageLocations' },
            { colour: '129, 166, 132', margin: 0, text: 'Manage Teams', icon: 'mdi-table-account', description: 'View, archive or create new Dog/Handler teams.', location: '/Admin/ManageTeams' },
            { colour: '190, 178, 200', margin: 0, text: 'Reports', icon: 'mdi-chart-line', description: 'Run a training summary report for a Dog/Handler team within a specified time period.', location: '/Admin/Reports' }
        ];
    }

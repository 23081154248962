
    import { Vue, Component, Prop } from 'vue-property-decorator'
    import Option from '../../models/Option'
    import api from '../../utils/api'
    import CurrentUser from '../../utils/CurrentUser';
    import { Rules } from '../../utils/Rules';

    @Component({
        name: 'ManageDropdowns'
    })
    export default class ManageDropdowns extends Vue {
        loadingData: boolean = false;
        showError: boolean = false;
        showErrorMessage: boolean = false;
        errorText: string = 'An error occurred';
        showSaveSuccess: boolean = false;
        savingData: boolean = false;
        valid: boolean = true;
        Rules: object = Rules;
        editOptionDialog: boolean = false;
        selectedOption: Option = { ID: 0, DisplayName: '', OptionCategory: '', OptionType: '', Symbol: '', SortOrder: 10, IsDeleted: false };
        category: string = '';
        OptionCategories: any[] = [{ name: 'Training Record', value: 'TrainingRecord' }, { name: 'Substance', value: 'Substance' }];

        Options: Option[] = [];
        OptionsFiltered: Option[] = [];
        OptionTypes: string[] = [];
        optionType: string = "";

        headers = [
            { text: 'Field Name', value: 'OptionType', sortable: false },
            { text: 'Display Name', value: 'DisplayName', sortable: false },
            { text: 'Status', value: 'IsDeleted', sortable: false, width: '15%' },
        ];

        async created() {
            await this.loadOptions();
        }

        async loadOptions() {
            this.loadingData = true;
            let optionTypesResponse = await api.get<Option[]>('Admin/GetCustomisableOptions?userToken=' + CurrentUser.currentUserToken());

            if (optionTypesResponse.ok) {
                var data = optionTypesResponse.data!
                this.Options = data;
                this.OptionsFiltered = data;

                this.OptionTypes = [...new Set(data.map(i => i.OptionType))];
            }
            else {
                this.showError = true;
            }

            this.loadingData = false;
        }

        updateOptionCategory() {
            this.optionType = '';
            this.OptionTypes = [...new Set(this.Options.filter(o => o.OptionCategory === this.category).map(i => i.OptionType))];

            this.filterOptions();
        }

        filterOptions() {
            this.OptionsFiltered = this.optionType ? this.Options.filter(o => o.OptionType === this.optionType) : (this.category ? this.Options.filter(o => o.OptionCategory === this.category) : this.Options);
        }

        editOption(selectedOption: Option) {
            this.selectedOption = Object.assign({}, selectedOption);
            this.editOptionDialog = true;
        }

        addNewOption() {
            this.selectedOption = { ID: 0, DisplayName: '', OptionCategory: '', OptionType: '', Symbol: '', SortOrder: 10, IsDeleted: false };
            this.editOptionDialog = true;
        }

        async updateOption() {
            (this.$refs!.form! as any).validate();

            if (this.valid) {
                this.savingData = true;
                let optionTypesResponse = await api.post<Option>('Admin/UpdateOptionText?userToken=' + CurrentUser.currentUserToken(), this.selectedOption);

                if (optionTypesResponse.ok) {
                    let returnedOption = optionTypesResponse.data!;
                    if (this.selectedOption.ID == 0) {
                        this.Options.push(returnedOption);
                    }
                    else {
                        this.Options = this.Options.map(o => o.ID !== returnedOption.ID ? o : returnedOption);
                    }
                    this.filterOptions();
                    this.editOptionDialog = false;
                    this.showSaveSuccess = true;
                }
                else {
                    this.showErrorMessage = true;
                    this.errorText = "Error: " + (optionTypesResponse.error ?? "Could not save Option");
                }
            }
            this.savingData = false;
        }

        archiveRestoreDropdown() {
            this.selectedOption.IsDeleted = !this.selectedOption.IsDeleted;
            this.updateOption();
        }

        getRowClass(item: Option) {
            return item.IsDeleted ? 'archived-row' : '';
        }
    }



    import { Vue, Component, Prop } from 'vue-property-decorator'
    import api from './utils/api';
    import CurrentUser from './utils/CurrentUser';
    import './main.css'
    import { Rules } from './utils/Rules';

    @Component
    export default class App extends Vue {
        drawer: boolean = false;
        changePasswordDialog: boolean = false;
        savingData: boolean = false;
        showErrorMessage: boolean = false;
        errorText: string = 'An error occurred';
        showSaveSuccess: boolean = false;
        valid: boolean = true;
        Rules: object = Rules;

        showNewPassword: boolean = false;
        showOldPassword: boolean = false;

        currentPassword = '';
        newPassword = '';

        navigate(path: string) {
            path = "/" + path;
            if (this.$route.path !== path) 
            {
                this.$router.push(path);    
            }
            else {
                this.drawer = false;
            }
        }

        logout() {
            this.drawer = false;
            localStorage.removeItem('CurrentUser');
            localStorage.removeItem('AccessToken');
            localStorage.removeItem('trainingRecordPagingData');
            localStorage.removeItem('trainingRecordSearchFields');
            this.navigate("login");
        }

        async changePassword() {
            (this.$refs!.cpForm! as any).validate();

            if (this.valid) {
                this.savingData = true;
                let changePasswordResponse = await api.post('Login/ChangePassword?userToken=' + CurrentUser.currentUserToken(), { CurrentPassword: this.currentPassword, NewPassword: this.newPassword });

                if (changePasswordResponse.ok) {
                    this.cancel();
                    this.showSaveSuccess = true;
                    this.savingData = false;
                }
                else {
                    this.showErrorMessage = true;
                }

                this.savingData = false;
            }
        }

        cancel() {
            this.currentPassword = '';
            this.newPassword = '';

            (this.$refs.cpForm as any).resetValidation();
            this.changePasswordDialog = false;
        }

        checkPermission(permission: string) {
            return CurrentUser.hasPermission(permission);
        }

        userAndRole() {
            return CurrentUser.currentUser().UserName + ' | ' + CurrentUser.currentUserRole();
        }
    }


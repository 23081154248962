export default {
    splitDate(inDate: Date) {
        var date = new Date(inDate.getTime() - (inDate.getTimezoneOffset() * 60000)).toISOString().split('T')[0];
        var time = (new Date(inDate.getTime() - (inDate.getTimezoneOffset() * 60000)).toISOString().split('T')[1]).split(':').slice(0, 2).join(':');

        return [ date, time ];
    },

    createDateTime(inDate: string, inTime: string) {

        return new Date(inDate + 'T' + inTime + ':00');
    },

    splitTimeMinutes(totalMinutes: number) {
        var hours = (totalMinutes - (totalMinutes % 60)) / 60;
        var minutes = totalMinutes - (hours * 60);

        return [ hours, minutes ]
    },

    convertToMinutes(hours: number, minutes: number) {
        return (hours * 60) + minutes
    }
}

    import { Vue, Component } from 'vue-property-decorator'
    import api from '@/utils/api'
    import LoginResponse from '@/models/LoginResponse'
    import { Rules } from '../utils/Rules';

    @Component
    export default class Login extends Vue {
        loadingData: boolean = false;
        username = '';
        password = '';
        loginFailed: boolean = false;
        rememberMe: boolean = false;
        valid: boolean = true;
        Rules: object = Rules;

        created() {
        }

        async submit() {
            (this.$refs!.form! as any).validate();

            if (this.valid) {
                this.loadingData = true;
                let result = await api.post<LoginResponse>("login/Authenticate", { Username: this.username, Password: this.password, RememberMe: this.rememberMe });
                if (result.ok) {
                    if (result.data?.ErrorMessage == "") {
                        localStorage.setItem('CurrentUser', JSON.stringify(result.data));
                        localStorage.setItem('AccessToken', result.data.AccessToken);
                        this.$router.replace(this.$route.query?.redirect as string || '/');
                    }
                    else {
                        localStorage.removeItem('CurrentUser');
                        this.loginFailed = true;
                    }
                }
                else {
                    localStorage.removeItem('CurrentUser');
                    this.loginFailed = true;
                }
                this.loadingData = false;
            }
        }

        /*Validation*/
        requiredField = [
            (value: string) => !!value || 'Required'
        ];
    }
